/* eslint-disable */
const axios = require('axios');
const io = require('socket.io-client');

const init_game_state = {
    socket: null,
    game_obj: {
        format: "",
        startingLife: 0,
        publicId: ""
    },
};

let game_state = Object.assign({}, init_game_state);

const game_mutations = {
    init_game(state) {
        game_state.game_obj = Object.assign({}, init_game_state);
        try {
            if(game_state.socket) {
                game_state.socket.disconnect();
                console.log("WE DISCONNECTED");
            }
            
        } catch(e) {
            console.log("Disonnect failed");
            console.log(e);
        }
        game_state.socket = null;

        return "";
    },

    init_socket(state, newState) {
        console.log('socket time!!');

        if(game_state.socket) {
            return;
        }
        
        const socket = io(process.env.VUE_APP_SERVER_PATH.split('/server')[0]);
        game_state.socket = socket;
        socket.on("connect", () => {
            socket.emit('join_game', state.player.secretId, game_state.game_obj.publicId);
            console.log("I HAVE CONNECTED");
        });

        socket.on("game_state", (newState) => {
            game_state.game_obj = newState;

            console.log(newState);

            if(Object.keys(newState.players).includes(state.player.publicId)) {
                console.log("SETTING UP AS PLAYER");
                const theThing = Object.assign(newState.players[state.player.publicId], {secretId: state.player.secretId, isObserver: false});
                this.commit('set_player', theThing);                
            } else if(Object.keys(newState.observers).includes(state.player.publicId)) {
                console.log("SETTING UP AS OBS")
                const theThing = Object.assign(newState.observers[state.player.publicId], {secretId: state.player.secretId, isObserver: true});
                console.log(theThing);
                this.commit('set_player', theThing);                
            }
            
            this.commit('set_player_events', state.game.game_obj.history);
        });

        socket.on("disconnect", () => {
            console.log("DISCONNECTED BY SERVER");
        });
    },

    async join_as_observer(state, playerInfo) {

        let player_response = {};

        player_response = (await axios.post(`${process.env.VUE_APP_SERVER_PATH}/game/${game_state.game_obj.publicId}/observer`, {
            ...playerInfo
        })).data;            

        state.player.secretId = player_response.secretId;
        state.player.publicId = player_response.publicId;

        this.commit('init_socket');

        return "Success";
    },

    async get_game(state, gameId) {
        
        let response = {};
        try {
            response = (await axios.get(`${process.env.VUE_APP_SERVER_PATH}/game/${gameId}`)).data;
        } catch(e) {
            return null;
        }

        if(response.publicId === gameId) {
            game_state.game_obj = response;
            return "SUCCESS";
        } else {
            return "FAILURE EPICO";
        }

        return "blah";
    },

    start_game(state, playerId) {

        if(game_state.socket) {
            game_state.socket.emit('start_game', playerId, state.game.game_obj.publicId);
            window.plausible('Game - Start');
        }
    },

    reset_game(state, playerId) {
        if(game_state.socket) {
            game_state.socket.emit('reset_game', playerId, state.game.game_obj.publicId);
            window.plausible('Game - Reset');
        }
    },

    // A host can update some properties of the game
    async change_game_settings(state, gameObj) {
        const response = (await axios.post(`${process.env.VUE_APP_SERVER_PATH}/game/${game_state.game_obj.publicId}?player_secret=${state.player.secretId}`, {
            ...gameObj
        })).data;        
    },

    commit_change_life(state, newState) {
        if(game_state.socket) {
            game_state.socket.emit('life_change', game_state.game_obj.publicId, state.player.secretId, newState.lifeDelta);
        } else {
            console.error("NO SOCKET");
        }
    },

    change_profile(state, newState) {

        console.log(newState);
        
        if(game_state.socket) {
            game_state.socket.emit('profile_change', game_state.game_obj.publicId, state.player.secretId, {
                color1: newState.color1,
                color2: newState.color2
            });
        } else {
            console.error("NO SOCKET");
        }
    },

    commit_change_counter(state, newState) {
        if(game_state.socket) {
            game_state.socket.emit('counter_change', game_state.game_obj.publicId, state.player.secretId, newState.counterName, newState.counterDelta, newState.counterIcon);
        } else {
            console.error("NO SOCKET");
        }
    },

    commit_change_commander_damage(state, newState) {
        if(game_state.socket) {
            game_state.socket.emit('commander_damage_change', game_state.game_obj.publicId, state.player.secretId, newState.counterName.split("_")[0], parseInt(newState.counterName.split("_")[1]), newState.counterDelta);
        } else {
            console.error("NO SOCKET");
        }
    },

    delete_counter(state, newState) {
        if(game_state.socket) {
            game_state.socket.emit('counter_delete', game_state.game_obj.publicId, state.player.secretId, newState.counterName);
        } else {
            console.error("NO SOCKET");
        }
    },

    roll_random(state, newState) {
        if(game_state.socket) {
            game_state.socket.emit('roll_random', game_state.game_obj.publicId, state.player.secretId, newState.rollType, newState.rollResult, newState.rollIcon);
        } else {
            console.error("NO SOCKET");
        }
    }
};

const game_actions = {
    async create_game(state, settings) {
        game_state.game_obj.format = settings.format;
        game_state.game_obj.startingLife = settings.startingLife;

        let response = null;
        try {
            response = await axios.post(`${process.env.VUE_APP_SERVER_PATH}/game`, {
                ...settings
            });
        } catch {
            return null;
        }

        

        game_state.game_obj = response.data;
        this.commit('set_player_game_id', response.data.publicId);

        return response.data.publicId;
    },

    async get_game(state, gameId) {
        let response = {};
        try {
            response = (await axios.get(`${process.env.VUE_APP_SERVER_PATH}/game/${gameId}`)).data;
        } catch(e) {
            const server_response = e.response;
            console.log(server_response);
            
            if(server_response.status === 404) {
                return null;
            }
        }

        //debugger;

        if(response.publicId === gameId) {
            game_state.game_obj = response;
            return response;
        } else {
            return "FAILURE EPICO";
        }
    },

    async join_game(state, playerInfo) {

        let player_response = {};
        
        // Connect and make a new player object
        // TODO - Anticipate failed joins from bad info.
        try {
            player_response = await axios.post(`${process.env.VUE_APP_SERVER_PATH}/game/${game_state.game_obj.publicId}/player`, {
                ...playerInfo
            });
        } catch (e) {
            player_response = e.response;
            return player_response;
        } finally {
            this.commit('set_joining', false);
        }        

        player_response = player_response.data;

        this.commit('set_player', {
            secretId: player_response.secretId,
            publicId: player_response.publicId,
            commanderList: player_response.commanderList
        });

        // Disconnect any existing sockets before reconnecting
        if(game_state.socket) {
            game_state.socket.disconnect();
            game_state.socket = null;
        }

        this.commit('init_socket');

        let game = await this.dispatch('get_game', game_state.game_obj.publicId);

        window.plausible('Game - Join');

        return "Success";
    },

    async join_as_observer(state, gameId) {

        console.log("JOINING TIME");

        let player_response = {};

        player_response = (await axios.post(`${process.env.VUE_APP_SERVER_PATH}/game/${gameId}/observer`, {})).data;

        this.commit('set_player', player_response);   
        this.commit('init_socket');
        this.commit('set_joining', false);

        return "Success";
    },

    async update_player(state, playerObj) {
        let response = {};
        try {
            response = (await axios.post(`${process.env.VUE_APP_SERVER_PATH}/game/${playerObj.gameId}/player/${playerObj.secretId}`, playerObj)).data;
        } catch {

        }
        
        return response;
    },

    async reconnect_to_game(state, gameId) {
        console.log("Trying to reconnect...");

        const playerInfo = {
            'name': localStorage.getItem('default--player_name'),
            'avatar': localStorage.getItem('default--player_avatar'),
            'publicId': localStorage.getItem('default--player_public'),
            'secretId': localStorage.getItem('default--player_secret'),
        };

        let server_response = {};
        try {
            server_response = await axios.get(`${process.env.VUE_APP_SERVER_PATH}/game/${gameId}/player/${playerInfo.secretId}`);
        } catch (e) {
            server_response = e.response;
        } finally {

        }

        if(server_response.status === 200) {
            // If this player is a member of the game, reconnect them
            this.commit('set_player', server_response.data);
            this.commit('init_socket');
            return 'player';
        } else if(server_response.status === 401) {
            this.commit('join_as_observer', playerInfo);
            return 'observer';
        } else if(server_response.status === 421) {
            this.commit('get_game', game_state.game_obj.publicId);
            // router.push(`/game/${game_state.game_obj.publicId}/observe`);
            return 'joinable';
        } else if(server_response.status === 404) {
            // If the game doesn't exist, gtfo
            return 'missing';
        }
    }
};
export {game_state, game_mutations, game_actions};