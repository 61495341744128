<style>
  @font-face {
    font-family: 'Avona Serif';
    src: local('Avona Serif'), url('../assets/fonts/AvonaSerif-Regular.woff2') format("woff2");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto', sans-serif;
    src: local('Roboto'), url('../assets/fonts/Roboto-Regular.ttf') format("ttf");
    font-weight: normal;
    font-style: normal;
  }
</style>
<template>
  <div id="app">
    <Navigation></Navigation>
    <div id="main" class="fancyScrollbar" :class="{'inGame': inGame}">
      <div class="primary--content">
        <router-view/>
      </div>
      <Footer v-if="!inGame"></Footer>
    </div>
    
  </div>
</template>

<script>
  import Navigation from '@/components/Navigation.vue';
  import Footer from '@/views/partials/Footer.vue';
  export default {
    components: {
      Navigation,
      Footer
    },
    methods: {

    },
    created() {
      if(localStorage.getItem('default--player_public')) {
        this.$store.commit('set_default_player');
      }
    },
    computed: {
      inGame: function() {

        const hexToRgb = hex =>
        hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
        ,(m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16)).join(',')



        const route = this.$route.path.split("/");
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        let appColorPrimary = '#8a2be2';
        document.documentElement.style.setProperty('--app-color--primary__hex', `${appColorPrimary}`);
        document.documentElement.style.setProperty('--app-color--primary__rgb', `${hexToRgb(appColorPrimary)}`);

        let appColorSecondary = '#0000FF';
        document.documentElement.style.setProperty('--app-color--secondary__hex', `${appColorSecondary}`);
        document.documentElement.style.setProperty('--app-color--secondary__rgb', `${hexToRgb(appColorSecondary)}`);

        return route[1] === 'game'
        && route[3] !== 'info'
        && route[3] !== 'waiting'
        && route[3] !== 'missing'
        && this.$store.state.game.game_obj.hasStarted;
      }
    }
  }
</script>

<style lang="less">
html, body {
    height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  box-sizing: border-box;
  color: #2c3e50;

  padding-top: 70px;
  overflow-x: hidden;
}

body {
  background: #121212;
  overscroll-behavior: auto;
  margin: 0;
}

#main { 
  
  padding: 20px 20px 0 20px;
  overflow-x: hidden;
  scroll-behavior: auto;
  height: ~"calc(var(--vh, 1vh)*100 - 70px)";
  box-sizing: border-box;

  .primary--content {
    min-height: 70vh;
  }

  &.inGame {
    padding-bottom: 100px;

    @media @tablet-down {
      padding-bottom: 0;
    }
  }

  @media @tablet-down {
    &.inGame {
      height: ~"calc(var(--vh, 1vh)*100 - 140px)";
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}


</style>
