<template>
  <div class="Button">
    <button :disabled="!isEnabled" :class="classString" type="button" @click="click()">
      <slot v-if="!processing"></slot>
      <font-awesome-icon  v-if="processing" class="fa-spin" :icon="['far', 'spinner']"/>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    processing: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function
    },
    type: {
      type: String,
      default: "primary"
    }
  },
  data: () => {
    return {

    }
  },
  computed: {
    isEnabled: function() {
      return this.enabled && !this.processing;
    },

    classString: function() {
      if(this.type === 'primary') {
        return 'corners--rounded font-color--primary app-color--primary';
      } else if(this.type === 'secondary') {
        return 'style--secondary';
      } else {
        return '';
      }
    }
  },
  methods: {
    click: function() {
      if(this.callback && this.isEnabled) {
        this.callback();
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.Button {
  height: 50px;
  margin-left: auto;
  margin-top: 15px;
  margin-right: auto;
  margin-bottom: 15px;
  padding-bottom: 15px;
  
  button {
    height: 50px;
    width: 250px;
    font-size: 1.5rem;
    cursor: pointer;
    box-shadow: 0px 2px 10px black;
    margin-top: 10px;
    transition: box-shadow .2s, margin-top .2s;
    border: 0;

    outline: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    /* Disables selecting text on buttons */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover:enabled {
      margin-top: 8px;
      box-shadow: 0px 4px 10px black;
    }

    &:hover:active {
      margin-top: 10px;
    }

    &:disabled {
      opacity: .5;
      cursor: initial;
    }

    .far {
      color: white;
      font-size: 32px;
    }

    &.style {
      &--secondary {
        color: @app-color-primary-1;
        background: @background-color-primary;
        box-shadow: none;
        opacity: .8;


        &:hover {
          box-shadow: none;
        }
      }
    }
  }
  
}
</style>
