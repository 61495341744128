<template>
  <footer class="Footer">
    <article class="footer--content">
      <section class="links">
        <nav class="section">
          <h3>HealthPotion</h3>
          <!-- <a v-if="isNotSafari" @click="showDownload()">Install App</a> -->
          <a href="javascript:;" @click="nav('/about')">About Us</a>
          <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/HealthPotionApp">News</a>
          <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSdt0MOMm_C_GzbyoxU6FrdEVxmCri9TwYSsQdSu75FMZCMDSw/viewform">Report Bug</a>
          <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSdXGkcwdk51_J2IRxqn32sFogEGIswhmsVN49-O0M_fVDW3Fw/viewform">Give Feedback</a>
        </nav>

        <nav class="section">
          <h3>Resources</h3>
          <a target="_blank" rel="noopener noreferrer" href="https://magic.wizards.com/en/content/commander-format">About Commander</a>
          <a target="_blank" rel="noopener noreferrer" href="https://scryfall.com/">Scryfall</a>
          <a target="_blank" rel="noopener noreferrer" href="https://edhrec.com/">EDHREC</a>
        </nav>

        <nav class="section">
          <h3>Community</h3>
          <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/HealthPotionApp">Twitter</a>
          <a target="_blank" rel="noopener noreferrer" href="https://ko-fi.com/healthpotionapp">Ko-fi</a>

        </nav>
      </section>

      <section class="disclaimer">
        HealthPotion.app is built in accordance with the Wizards of the Coast Fan Content Policy. The rights to any card imagery presented herein are retained by their respective owners. Symbols, card data, and associated iconography are owned by Wizards of the Coast. HealthPotion.app is provided 'as-is' and makes no warranties or guarantees of performance or concistency.
      </section>
    </article>
  </footer>
</template>

<script>
  import router from '@/router'
  export default {
    name: 'Footer',
    components: {
    },
    mounted() {

      /*

      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        

        if (window.matchMedia('(display-mode: standalone)').matches) {  
            window.plausible('PWA - App Opened');
        }
      });

      window.addEventListener('appinstalled', () => {
        console.log("app installed");
        window.plausible('PWA - App Installed');
      });
      */
    },
    data() {
      return {
        deferredPrompt: null
      }
    },

    computed: {
      isNotSafari: function() {
        const ua = navigator.userAgent.toLowerCase(); 
        if (ua.indexOf('safari') !== -1) { 
          if (ua.indexOf('chrome') > -1) {
            return true;
          } else {
            return false;
          }
        }

        return true;
      }
    },
    methods: {
      showDownload() {
        if(this.deferredPrompt) {
          this.deferredPrompt.prompt();
        } else {
          console.log("No install prompt :(")
        }
      },
      nav: function(path) {
        router.push({ path: path });
      },
    }
  }
</script>

<style scoped lang="less">
.Footer {
  z-index: 0;
  background: #222;
  width: 100vw;
  margin: 100px 0 0 0;
  padding: 0 0 30px 0;
  overflow: auto;
  position: relative;
  left: 0;
  left: calc(-50vw + 50%);
  min-height: 20vh;
  font-size: .75em;
  color: #777;


  .footer--content {
    width: 70%;
    max-width: 600px;
    margin: auto;

    display: flex;
    flex-flow: column;
  }

  .links {
    display: flex;
    flex-flow: row;
    text-align: left;
    @media @phone-down {
      display: flex;
      flex-flow: column;
      text-align: center;
    }
    .section {
      display: flex;
      flex-flow: column;
      flex: 1;
      padding: 10px;


      h3 {
        border-bottom: 1px solid #ccc;
        padding: 5px;
        margin-top: 0;
        margin-bottom: 5px;
        color: white;
      }
      
      a {
        margin: 3px;
        color: #ccc;
        text-decoration: none;
        cursor: pointer;
        
        &:hover {
          color: @app-color-primary-1;
        }
      }    
  }


  }

  .disclaimer {
    margin-top: 25px;
    opacity: .5;
  }
}
</style>