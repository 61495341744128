import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/main.js'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
// Rewrite the push method on the prototype and handle the error message uniformly
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'HealthPotion.app',
    component: Home
  },
  {
    path: '/about',
    name: 'About Us',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/new',
    name: 'New Game',
    component: () => import(/* webpackChunkName: "about" */ '../views/New.vue'),
    beforeEnter (to, from, next) {

      store.commit('init_player');
      store.commit('init_game');
      next();
    },
  },
  {
    path: '/join',
    name: 'Join Game',
    component: () => import(/* webpackChunkName: "about" */ '../views/Join.vue')
  },
  {
    path: '/game/:gameId',
    name: 'Game',
    component: () => import(/* webpackChunkName: "about" */ '../views/Game.vue'),
    async beforeEnter (to, from, next) {

      if(store.state.player.isConnected) {
        next();
        return;
      }

      if(to.name === 'Missing Game') {
        next();
      }

      if(to.name === "Observe Game") {
        console.log("GOING");
        next();
      }

      // We set a query param when joining via qr code, and need to init here.
      if(to.query.joining) {
        await store.commit('get_game', to.params.gameId)
      }

      // TODO - THIS MAY BE SUPER BROKEN
      next();
      /*
      if(to.name === 'Gamme | Player Info') {
        if(store.state.player.isJoining || to.query.joining) {
          next();
          return;
        } else {
          next(`/join`);
          return;
        }
      }*/


      const player_game_id = localStorage.getItem('game_id');

      // If we're trying to join the wrong game from memory, join the one from the url
      if(player_game_id && to.params.gameId !== player_game_id) {
        store.commit('init_player');
        store.commit('init_game');
        next(`/game/${to.params.gameId}/lobby`);
        return;
      }

      // First we check if the player says they should be in a game.
      if(player_game_id) {

        // Get this user's info...
        const player_public = localStorage.getItem('default--player_public');

        let game_obj = store.state.game.game_obj;

        // If we don't have this player's game info, get it.
        if(!game_obj.publicId) {
           game_obj = await store.dispatch('get_game', player_game_id);
        }

        // If we can't find the game...
        if(!game_obj) {
          next(`/game/${to.params.gameId}/missing`);
          return;
        }

        // If this person is a player or observer...
        if(Object.keys(game_obj.players).includes(player_public) ||
          Object.keys(game_obj.observers).includes(player_public)) {
          let state_response = await store.dispatch('reconnect_to_game', player_game_id);

          if(state_response === 'observer') {
            next(`/game/${to.params.gameId}/observe/life`);
            return;
          } else if(state_response === 'joinable') {
            next(`/game/${to.params.gameId}/info`);
            return;
          }

          // Send them to the lobby to be sorted out.
          next(`/game/${to.params.gameId}/lobby`);
          return;
        }

      } else {
        let game_obj = await store.dispatch('get_game', to.params.gameId);
        const player_public = localStorage.getItem('default--player_public');

        // If the game does not exist, leave.
        if(!game_obj) {  
          next(`/game/${to.params.gameId}/missing`);
          return;
        }

        if(!player_public) {
          store.commit('set_player_game_id',  to.params.gameId);
          if(!game_obj.settings.acceptingPlayers) {
            await store.dispatch('join_as_observer', to.params.gameId);
            next(`/game/${game_obj.publicId.toUpperCase()}/lobby`).catch(() => {});
            return;
          } else {
            next(`/game/${game_obj.publicId.toUpperCase()}/info`);
            return;
          }
        } else {

          // If this person is a player or observer...
          if(Object.keys(game_obj.players).includes(player_public) ||
            Object.keys(game_obj.observers).includes(player_public)) {
            await store.dispatch('reconnect_to_game', to.params.gameId);
            // Send them to the lobby to be sorted out.
            next(`/game/${to.params.gameId}/lobby`).catch(() => {});
            return;
          }

          if(!game_obj.settings.acceptingPlayers) {
            // Join using our user info.
            await store.commit('join_as_observer', store.state.player);
            next(`/game/${game_obj.publicId.toUpperCase()}/lobby`).catch(() => {});
            return;
          } else {
            next(`/game/${game_obj.publicId.toUpperCase()}/info`);
            return;
          }
        }
      }
      next();
    },
    children: [
      {
        path: 'info',
        name: 'Game | Player Info',
        component: () => import('../views/PlayerInfo.vue'),
        beforeEnter (to, from, next) {
          next();
        },
        props: true
      },
      {
        path: 'life',
        name: 'Game | Life',
        component: () => import('../views/game/Life.vue')
      },
      {
        path: 'commander-damage',
        name: 'Game | Commander Damage',
        component: () => import('../views/game/CommanderDamage.vue')
      },
      {
        path: 'counters',
        name: 'Game | Counters',
        component: () => import('../views/game/Counters.vue')
      },
      {
        path: 'history',
        name: 'Game | History',
        component: () => import('../views/game/History.vue')
      },
      {
        path: 'settings',
        name: 'Game | Settings',
        component: () => import('../views/game/Profile.vue')
      },
      {
        path: 'lobby',
        name: 'Game | Lobby',
        component: () => import('../views/game/Waiting.vue'),
        async beforeEnter (to, from, next) {
          // We kick people to the lobby when we want them to enter the game, and sort them out here.
          const game = store.state.game.game_obj;
          if(game && game.hasStarted) {
              if(Object.keys(game.players).includes(store.state.player.publicId)) {
                next(`/game/${game.publicId}/life`);
                return;
              } else {
                next(`/game/${game.publicId}/observe/life`).catch(() => {});
                return;
              }
          } else {
            if(!Object.keys(game.players).includes(store.state.player.publicId)) {
              next(`/game/${game.publicId}/info`);
              return;
            }
            next();
            return;
          }
        }
      },
      {
        path: 'missing',
        name: 'Game | Missing Game',
        component: () => import('../views/game/Missing.vue')
      },
      {
        path: 'observe',
        name: 'Observer | Observe Game',
        component: () => import('../views/game/Observe.vue'),
        async beforeEnter (to, from, next) {
          await store.dispatch('reconnect_to_game', to.params.gameId);
          next();
        },
        children:[
          {
            path: 'life',
            name: 'Observer | Life',
            component: () => import('../views/game/Life.vue')
          },
          {
            path: 'history',
            name: 'Observer | History',
            component: () => import('../views/game/History.vue')
          },
          {
            path: 'commander-damage',
            name: 'Observer | Commander Damage',
            component: () => import('../views/game/CommanderDamageTable.vue')
          },
          {
            path: 'profile',
            name: 'Observer | Profile',
            component: () => import('../views/game/Life.vue')
          },
          {
            path: 'profile',
            name: 'Observer | Settings',
            component: () => import('../views/game/ObserverSettings.vue')
          },
        ],
        props: true
      }
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
