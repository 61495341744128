/* eslint-disable */
const axios = require('axios');

const init_state = {
    name: "",
    avatar: "",
    publicId: null,
    secretId: null,
    commanderList: [{
        name: '',
        id: '',
        image_uri: '',
    }, {
        name: '',
        id: '',
        image_uri: '',
    }],
    color1: '#000000',
    color2: '#222222',
    isConnected: false,
    isJoining: false,
    isObserver: false,

    eventAckList: [],
    newEventList: [],

    debounceTime: 1500,

    // Determines whether or not the user is trying to commit a change.
    hasPendingChanges: false,
    updateDebounce: {},
    pendingOldState: {},
    pendingState: {},

    // Settings unique only locally to the player.
    // These should never make it to the server.
    settings: {
        streamingMode: false,
        showTools: false,
    }
};

let player_state = Object.assign({}, init_state);

function debounceUpdate(fieldName, _this, state, newValue, callBack) {

    let _ref = player_state;

    // Reset any debounce timers
    if(_ref.updateDebounce[fieldName]) {
      clearTimeout(_ref.updateDebounce[fieldName]);
    }

    // Init pending life deltas
    if(!_ref.pendingOldState[fieldName]) {
        _ref.pendingOldState[fieldName] = state.player[fieldName];
    }

    const updatedState = {};
    updatedState[fieldName] = newValue;
    _ref.pendingState = Object.assign(_ref.pendingState, updatedState);

    // Update the pending delta and redefine the commit debounce
    _ref.updateDebounce[fieldName] = setTimeout(function(_this, _ref, callBack){
        // console.log(_ref.pendingOldState[fieldName]);
        // console.log(_ref.pendingState[fieldName]);
        // console.log(_this);
        callBack(_this, _ref);
        delete _ref.updateDebounce[fieldName];
        delete _ref.pendingOldState[fieldName];
        delete _ref.pendingState[fieldName];
    }, _ref.debounceTime, _this, _ref, callBack);

    
}

const player_mutations = {
    init_player(state, newState=null) {
        state.player = {...init_state};
        localStorage.removeItem('player_name');
        localStorage.removeItem('player_avatar');
        localStorage.removeItem('player_public');
        localStorage.removeItem('player_secret');
        localStorage.removeItem('game_id');
    },
    set_name(state, name) {
        state.player.name = name;
    },

    set_joining(state, isJoining) {
        state.player.isJoining = isJoining;
    },

    set_profile(state, newState) {
        console.log(newState);
        state.player.color1 = newState.color1;
    },

    set_avatar(state, avatar) {
        state.player.avatar = avatar;
    },

    set_player_game_id(state, gameId) {
        state.player.game_id = gameId;
        localStorage.setItem('game_id', gameId);
    },

    set_default_player(state, newState) {
        console.log("setting default player");
        this.commit('set_player', {
            name: localStorage.getItem('default--player_name'),
            avatar: localStorage.getItem('default--player_avatar'),
            publicId: localStorage.getItem('default--player_public'),
            secretId: localStorage.getItem('default--player_secret'),
            commanderList: [JSON.parse(localStorage.getItem('default--player_commander_0')), JSON.parse(localStorage.getItem('default--player_commander_1'))]
        });
    },

    set_player(state, player) {

        // Take the new state, but add any pending deltas we have locally.
        let newState = Object.assign(player, player_state.pendingState, {
            pendingOldState: player_state.pendingOldState,
            eventAckList: state.player.eventAckList,
            newEventList: state.player.newEventList,
            settings: state.player.settings,
        });

        state.player = newState;

        state.player.debounceTime = player_state.debounceTime;


        state.player.name ? localStorage.setItem('player_name', state.player.name) : '';
        state.player.avatar ? localStorage.setItem('player_avatar', state.player.avatar) : '';
        state.player.publicId ? localStorage.setItem('player_public', state.player.publicId) : '';
        state.player.secretId ? localStorage.setItem('player_secret', state.player.secretId) : '';
        state.game.game_obj.publicId ? localStorage.setItem('game_id', state.game.game_obj.publicId) : '';
        console.log(state.player);
        state.player.commanderList[0] && state.player.commanderList[0].name ? localStorage.setItem('default--player_commander_0', JSON.stringify(state.player.commanderList[0])) : '';
        state.player.commanderList[1] && state.player.commanderList[1].name ? localStorage.setItem('default--player_commander_1', JSON.stringify(state.player.commanderList[1])) : '';

        if(!localStorage.getItem('default--player_secret') || !localStorage.getItem('default--player_public')) {
            localStorage.setItem('default--player_public', state.player.publicId);
            localStorage.setItem('default--player_secret', state.player.secretId);    
        }
    },

    // Used to store into memory default player settings for future games.
    set_player_defaults(state, player) {
        // Set defaults for future games
        state.player.name ? localStorage.setItem('default--player_name', state.player.name) : '';
        state.player.avatar ? localStorage.setItem('default--player_avatar', state.player.avatar) : '';
        state.player.commanderList[0] && state.player.commanderList[0].name ? localStorage.setItem('default--player_commander_0', JSON.stringify(state.player.commanderList[0])) : '';
        state.player.commanderList[1] && state.player.commanderList[1].name ? localStorage.setItem('default--player_commander_1', JSON.stringify(state.player.commanderList[1])) : '';
    },

    set_player_settings(state, newState) {
        state.player.settings = Object.assign(state.player.settings, newState);
    },

    set_player_events(state, newState) {

        if(!newState.length) {
            return;
        }

        // If we're refreshing, just set it.
        if(state.player.eventAckList.length === 0 && newState.length > 1) {
            state.player.eventAckList = new Array(...newState);
        } else {

            const newEventList = [];

            for(const newEvent of newState) {
                if(!state.player.eventAckList.find(x => x.id === newEvent.id)) {
                    newEventList.push(newEvent);
                    console.log(state.player);
                    this.dispatch('reset_events', {event: newEvent, debounceTime: state.player.debounceTime});
                }
            }

            state.player.newEventList = newEventList.slice();
        }
    },

    kill_events(state, newState) {
        state.player.newEventList = [].slice();
        state.player.eventAckList.push(newState);
        state.player.eventAckList = state.player.eventAckList.slice();

        // This is kind of a hack to get the state to update, hopefully there's
        // no unforseen repercussions??
        state.player = Object.assign({}, state.player, state.player);
    },

    change_life(state, newState) {
        const newValue = state.player.life + newState.lifeDelta;

        debounceUpdate('life', this, state, newValue, (_this, _ref) => {
            // console.log(_this);
            // console.log(_ref.pendingOldState);
            // console.log(_ref.pendingState);
            _this.commit('commit_change_life', {"lifeDelta": _ref.pendingState['life'] - _ref.pendingOldState['life']});
        });
        
        state.player.life = newValue;
    },

    change_commander_damage(state, newState) {
        doChangeCounter(state, newState, this, 'commanderDamage', 'commit_change_commander_damage');
    },

    change_counter(state, newState) {
        doChangeCounter(state, newState, this, 'counters', 'commit_change_counter');
    },
};

const player_actions = {

    async reset_events(state, newEvent) {
        let _this = this;
        console.log(state);
        console.log(newEvent);
        await setTimeout(function() {
            _this.commit('kill_events', newEvent.event);
        }, newEvent.debounceTime);
    },

    async get_games(state, secretId) {
        const response = (await axios.get(`${process.env.VUE_APP_SERVER_PATH}/player/${secretId}/games`)).data;
        return response;
    }
}

const doChangeCounter = (state, newState, instance, type, commitFunctionName) => {
    const counters = JSON.parse(JSON.stringify(state.player[type]));
    let counter = counters[newState.counterName];
    let counterIcon = newState.counterIcon;
    let forceCommit = newState.forceCommit;

    if(counter) {
        counter.value = counter.value + newState.counterDelta;
    } else {
        counters[newState.counterName] = {
            "name": newState.counterName,
            "icon": counterIcon,
            "value": newState.counterDelta
        };

        counter = counters[newState.counterName];
    }

    debounceUpdate(type, instance, state, counters, (_this, _ref) => {

        for(let counterName in _ref.pendingState[type]) {
            
            let oldCounter = _ref.pendingOldState[type][counterName];
            if(!oldCounter) {
                oldCounter = {
                    "name": counterName,
                    "icon": counterIcon,
                    "value": 0
                };
            }

            let newCounter = _ref.pendingState[type][counterName];
            let counterDelta = newCounter.value - oldCounter.value;

            if(counterDelta !== 0 || forceCommit === newCounter.name) {
                _this.commit(commitFunctionName, {"counterName": newCounter.name, counterDelta:counterDelta, counterIcon:counterIcon});
            }
        }
        //_ref.pendingState['counters']['life'] - _ref.pendingOldState['life']
        
    });

    // Does the intermediate update so we can update right away.
    // Very important!!!
    state.player[type] = counters;
}

export {player_state, player_mutations, player_actions};