<template>
  <div class="home">
    <section id="splash">
        <img class="splash" src="@/../assets/logo-small.png" height="300px" />
        <h2 class="title font-color--primary">HealthPotion.app</h2>
        <h2 class="title--sub font-color--primary font-style--readable">Refreshingly easy Commander life tracking</h2>
        <div class="buttonRow">
            <Button :callback="function(){navigateDrawer('new')}">New Game</Button>
            <Button :callback="function(){navigateDrawer('join')}" type="secondary">Join Game</Button>
        </div>
    </section>
    <section v-if="latestGame" class="secondary rejoinGame">
        <h2 class="font-color--primary">Rejoin Recent Game?</h2>
        <h2 class="font-color--primary font-style--readable lobbyCode">{{latestGame.publicId}}</h2>
        <Button :callback="function(){navigateDrawer(`/game/${latestGame.publicId.toUpperCase()}/lobby`)}">Rejoin</Button>
            
    </section>
    <section class="secondary">
        <figure>
            <img id="usageImg" src='@/../assets/homepage/homepage33.png' height="600px"/>
            <figcaption class="font-color--primary"></figcaption>
        </figure>
        <div class="three-up font-color--primary">
            <section>
                <div class="asset">
                    <font-awesome-icon :icon="['fas', 'heart', ]" size="4x" />
                </div>
                <div class="content font-style--readable">
                    HealthPotion provides tracking for health, commander damage, counters, and more
                </div>
            </section>
            <section>
                <div class="asset">
                    <font-awesome-icon :icon="['fas', 'mobile-android-alt']" size="4x" />
                </div>
                <div class="content font-style--readable">
                    Each player tracks their own game state on their own phone - no more shared devices
                </div>
            </section>
            <section>
                <div class="asset">
                    <font-awesome-icon :icon="['fas', 'wifi']" size="4x" />
                </div>
                <div class="content font-style--readable">
                    HealthPotion keeps each player's game state in sync over wifi and creates a shared history
                </div>
            </section>
        </div>
    </section>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import router from '@/router'
export default {

    name: 'Home',
    components: {
        Button,
    },
    data: () => {
      return {
        latestGame: null,
        latestGamePlayer: null
      }
    },
    mounted() {
        this.getGameListForPlayer();
    },
    methods: {
        navigateDrawer: function(route) {
            router.push({ path: route });
        },
        getGameListForPlayer: async function() {
            const playerSecret = localStorage.getItem('default--player_secret');
            if(playerSecret) {
                const gameList = (await this.$store.dispatch('get_games', playerSecret)).games;
                if(gameList.length) {
                    const latestGame = gameList[gameList.length-1];
                    const minAgo = (new Date().getTime() - latestGame.game.lastUpdate) / 1000 / 60;

                    if(minAgo < 30) {
                        this.latestGame = latestGame.game;
                        this.latestGamePlayer = latestGame.player;
                    }
                }
            }
        }
    }
}
</script>

<style scoped lang="less">
.home {
    max-width: 1200px;
    margin: auto;

    #splash {
        
    }

    .splash {
        height: clamp(200px, 30vw, 300px);
    }

    .secondary.rejoinGame {
        display: flex;
        flex-flow: column;

        .lobbyCode {
            font-size: 2em;
        }
    }

    .secondary {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        padding-top: 30px;

        border-top: 3px solid @background-color-secondary;

        @media @phone-down {
            flex-flow: column;
        }

        #usageImg {
            
        }

        .three-up {
            width: 50%;
            display: flex;
            flex-flow: column;
            align-items: center;

            @media @phone-down {
                width: 100%;
                margin-top: 30px;
            }


            section {
                display: flex;
                flex-flow: row;
                margin: 15px;
                width: 100%;

                .asset {
                    min-width: 80px;
                    box-sizing: border-box;
                }

                .content {
                    display: flex;
                    
                    align-items: center;
                    padding-left: 15px;
                    text-align: left;
                }

                @media @phone-down {
                    flex-flow: column;
                    justify-content: center;
                    align-items: center;

                    .asset {
                        width: 100%;
                    }

                    .content {
                        text-align: center;
                        padding-left: 0;
                        margin-top: 10px;
                        width: 80%;
                    }
                }
            }
        }
    }

    .title {
        font-size: clamp(32px, 5vw, 52px);
        margin: 0;

        &--sub {
            font-size: clamp(12px, 2vw, 16px);
            padding: 15px;
            margin: auto;

            @media @phone-down {

            }
        }
    }

    .rejoin {
        background: red;
    }

    .buttonRow {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;

        .Button {
            &:first-child {
                margin-right: 15px;
            }

            &:last-child {
                margin-left: 15px;
            }
        }

        @media @phone-down {
            flex-flow: column;

            .Button {
                &:first-child {
                    margin: auto;
                }

                &:last-child {
                    margin: auto;
                }
            }            
        }
    }
}

</style>