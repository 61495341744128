import Vue from 'vue'
import Vuex from 'vuex'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import {player_state, player_mutations, player_actions} from '@/state_objs/player.js';
import {game_state, game_mutations, game_actions} from '@/state_objs/game.js';
import 'mana-font/css/mana.min.css';
import 'keyrune/css/keyrune.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFlaskPotion,
  faHelmetBattle,
  faLayerGroup,
  faLongArrowAltRight,
  faHeart,
  faBars,
  faCog,
  faCoin,
  faCoins,
  faCheck,
  faDiceD6,
  faDiceD20,
  faSpinner,
  faScrollOld,
  faStar,
  faTimes,
  faTimesCircle,
  faUser,
  faUsers as farUsers } from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowRight,
  faBug,
  faCoffee,
  faDragon,
  faHeart as fasHeart,
  faHouse,
  faTriangle,
  faLightbulb,
  faMobileAndroidAlt,
  faQrcode,
  faQuestion,
  faShield,
  faSkull,
  faSwords,
  faTools,
  faUser as faUserSolid,
  faUserFriends,
  faUsers,
  faWifi } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faFlaskPotion);
library.add(faBars);
library.add(faBug);
library.add(faCoffee);
library.add(faStar);
library.add(faHelmetBattle);
library.add(faLayerGroup);
library.add(faLongArrowAltRight);
library.add(faHeart);
library.add(faCheck);
library.add(faDiceD6);
library.add(faDiceD20);
library.add(faDragon);
library.add(farUsers);
library.add(faCoin);
library.add(faSpinner);
library.add(fasHeart);
library.add(faHouse);
library.add(faUser);
library.add(faUserSolid);
library.add(faUsers);
library.add(faTimes);
library.add(faTimesCircle);
library.add(faTriangle);
library.add(faCog);
library.add(faCoins);
library.add(faMobileAndroidAlt);
library.add(faLightbulb);
library.add(faQrcode);
library.add(faQuestion);
library.add(faScrollOld);
library.add(faShield);
library.add(faSkull);
library.add(faSwords);
library.add(faTools);
library.add(faUserFriends);
library.add(faArrowRight);
library.add(faWifi);

Vue.config.productionTip = false;
Vue.component('font-awesome-icon', FontAwesomeIcon);
const dotenv = require('dotenv');
dotenv.config();


Vue.prototype.$GAME_FORMAT_LIST = ['commander'];
Vue.prototype.$GAME_STARTING_LIFE_LIST = [40, 30, 25, 20];

Vue.prototype.$PLAYER_AVATAR_LIST = ['chandra', 'garruk', 'ajani', 'jace', 'liliana'];
Vue.prototype.$COMMON_COUNTERS = {
          'poison': {key: 'poison', name: 'poison', icon: 'far fa-skull-crossbones'},
          'energy': {key: 'energy', name: 'energy', icon: 'ms ms-e'},
          'experience': {key: 'experience', name: 'experience', icon: 'ss ss-c15'},
          'commanderTax': {key: 'commanderTax', name: 'commander tax', icon: 'far fa-helmet-battle'}
        };

Vue.use(router);
Vue.use(Vuex);

if(process.env.NODE_ENV === 'production') {
  console.log = function() {};
  console.warn = function() {};
  console.error = function() {};

  // Add plausible tracking only in prod
  let plausible = document.createElement('script');
  plausible.setAttribute('src',"https://plausible.io/js/plausible.js");
  plausible.setAttribute('data-domain',"healthpotion.app");
  document.head.appendChild(plausible);

  window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }
} else {
  window.plausible = function(){};
}


function updateResize() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('resize', updateResize);

const store = new Vuex.Store({
  state: {
    count: 0,
    player: player_state,
    game: game_state,
  },
  mutations: {
    increment (state) {
      state.count++
    },
    ...player_mutations,
    ...game_mutations
  },
  actions: {
    ... game_actions,
    ... player_actions
  }
});

export default store

new Vue({
  router,
  store: store,
  watch: {
    '$route': function() {
      
      // Resets scroll position every route change.
      document.getElementById('main').scrollTop = 0;
    }
  },
  render: h => h(App)
}).$mount('#app');


