<template>
  <div class="Navigation">
    <nav class="navRail z-index--1">
      <ul>
        <li class="navItem appIcon" @click="navHome">
          <img src="/img/icons/nonmaskable/apple-touch-icon.png" height="40px" />
        </li>
        <li class="navItem pageName">
          <h2 class="name name--primary">
            <span>{{primaryNavName}}</span>
            <span class="gameCode font-style--readable" v-if="$store.state.game.game_obj.publicId && primaryNavName == 'Game'">
              - {{$store.state.game.game_obj.publicId}}
            </span>
          </h2>
          <transition name="fade">
            <h3 :key="secondaryNavName" v-if="secondaryNavName" class="name name--secondary">
              {{secondaryNavName}}
            </h3>
          </transition>
        </li>
        <li class="navItem hamburger" @click="toggleDrawer">
          <font-awesome-icon :icon="['far', 'bars']" />
        </li>
      </ul>
    </nav>
    <nav class="drawer" :class="{'open': drawerOpen}">
      <div class="closeRow">
        <font-awesome-icon class="closeIcon" @click="toggleDrawer" :icon="['far', 'times']"  size="2x" />
      </div>
      <ul class="drawer--primary">
        <li class="drawerItem" v-on:click="navigateDrawer('/')">
          <font-awesome-icon :icon="['fas', 'house']" />&nbsp;<span class="drawerText">Home</span>
        </li>
        <li class="drawerItem" v-on:click="navigateDrawer('/new')">
          <font-awesome-icon :icon="['fas', 'user']" />&nbsp;<span class="drawerText">New Game</span>
        </li>
        <li class="drawerItem" v-on:click="navigateDrawer('/join')">
          <font-awesome-icon :icon="['fas', 'users']" />&nbsp;<span class="drawerText">Join Game</span>
        </li>
      </ul>
      <div id="divider"></div>
      <ul class="drawer--secondary">
        <li class="drawerItem"  v-on:click="navigateOut('https://docs.google.com/forms/d/e/1FAIpQLSdt0MOMm_C_GzbyoxU6FrdEVxmCri9TwYSsQdSu75FMZCMDSw/viewform')">
          <font-awesome-icon :icon="['fas', 'bug']" />&nbsp;<span class="drawerText">Report Bug</span>
        </li>
        <li class="drawerItem"  v-on:click="navigateOut('https://docs.google.com/forms/d/e/1FAIpQLSdXGkcwdk51_J2IRxqn32sFogEGIswhmsVN49-O0M_fVDW3Fw/viewform')">
          <font-awesome-icon :icon="['fas', 'lightbulb']" />&nbsp;<span class="drawerText">Give Feedback</span>
        </li>
        
        <li class="drawerItem"  v-on:click="navigateOut('https://ko-fi.com/healthpotionapp')">
          <font-awesome-icon :icon="['fas', 'coffee']" />&nbsp;<span class="drawerText">Support Us</span>
        </li>
      </ul>
    </nav>
    <div class="frost" @click="toggleDrawer" :class="{'frosty': drawerOpen}">
    </div>
  </div>
</template>

<script>
import router from '@/router'
export default {
  name: 'NavigationSecondaryItem',
  props: [],
  data: () => {
    return {
      drawerOpen: false,
      router: router,
    }
  },
  computed: {
    primaryNavName: function() {
      if(router.history.current.name) {
        const splitNav = router.history.current.name.split("|");
        const primaryNav = splitNav[0].trim();
        return primaryNav;
      } else {
        return '';
      }
    },
    secondaryNavName: function() {
      
      
      if(router.history.current.name) {
        const splitNav = router.history.current.name.split("|");
        if(splitNav[1]) {
          const secondaryNav = splitNav[1].trim();
          return secondaryNav;
        } else {
          return '';
        }
        
      } else {
        return '';
      }
    }
  },
  methods: {
    toggleDrawer: function() {
      this.drawerOpen = !this.drawerOpen;
    },

    navigateDrawer: function(route) {
      router.push({ path: route });
      this.toggleDrawer();
    },

    navigateOut: function(url) {
      window.open(url, "_blank");
    },

    navHome: function() {
      router.push({ path: '/' });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.Navigation {
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  position: fixed;
  -webkit-backface-visibility: hidden;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  pointer-events: none;

  .frost {
    height: 100vh;
    width: 100vw;
    position: absolute;
    background: black;
    z-index: 9000;
    top: 0;
    left: 0;

    pointer-events: none;
    opacity: 0;
    transition: opacity linear @animation-duration--fast;

    &.frosty {
      pointer-events: initial;
      opacity: .5;
    }
  }
  .drawer {
    height: 100vh;
    max-width: 256px;
    width: 75vw;

    background: @background-color-secondary;
    color: @font-color-primary;
    display: flex;
    flex-flow: column;
    position: absolute;
    box-sizing: border-box;
    pointer-events: initial;

    font-size: 14px;
    

    z-index: 10000;
    top: 0;
    right: -256px;
    transition: right linear @animation-duration--fast;

    #divider {
      border: 1px solid grey;
      margin: 0 15px 0 15px;
    }

    .drawerItem {
      font-size: 1.5em;
      padding: 10px 15px 10px 15px;
      cursor: pointer;
      user-select: none;

      a {
        text-decoration: none;
        background: red;

        &:visited {
          color: @font-color-primary;
        }
      }

      &:hover {
        background: @background-color-primary;
      }

      svg {
        width: 32px;
        text-align: center;
        margin-right: 5px;
      }
      
    }

    &.open {
      right: 0;
      box-shadow: @box-shadow--normal;
    }

    ul {
      list-style-type: none;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      padding: 0;
      text-align: left;
    }

    .closeRow {
      text-align: right;
      
    }

    .closeIcon {
      padding: 15px;
      
      cursor: pointer;
      
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
      
      
    }
  }

  .navRail {
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    background: red;
    
    display: flex;
    flex-flow: row;
    z-index: 10;
    background: @app-color-primary-1;
    background: #222;
    width: 100%;
    pointer-events: initial;
    ul {
      display: flex;
      flex-flow: row;
      padding: 0;
      margin: 0;
    }

    * {
      color: @font-color-primary; 
      text-decoration: none;
    }

    .navItem {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .appIcon {
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .pageName {
      display: flex;
      flex-flow: column;
      text-align: left;
      cursor: initial;
      .name {
        margin: 0;
        width: 100%;

        &--primary {

          font-size: 1.25em;
          &:only-child {
            font-size: 1.5em;
          }

          &:not(:only-child) {
            position: absolute;
            left: 70px;
            top: 15px;            
          }

          .gameCode {
            font-size: .75em;
          }
        }

        &--secondary {
          font-size: .75em;
          font-style: italic;
          position: absolute;
          left: 70px;
          top: 40px;

          

          &.fade-enter-active {
            transition: opacity .2s, top .2s;
          }

          &.fade-enter, &.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
            opacity: 0;
            top: 45px;
          }
        }
      }
    }

    .hamburger {
      position: fixed;
      right: 0;
      top: 0;

      font-size: 1.5em;
      padding: 15px;
    }    
  }

}
</style>
